import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import {container} from "react-bootstrap"
import Layout from "../../components/layout"

export default function PopTheBalloon({data}) {
  return (
    <Layout>

      <Helmet>
          <meta charSet="utf-8" />
          <title>Pop the Balloon | CheePlus</title>
          <link rel="canonical" href="https://www.cheeplus.com/about" />
      </Helmet>



      <h1>Pop the Balloon</h1>
      <img src="https://i.postimg.cc/jq78XtnX/icon-PTB-2-512x512.png" width="128" />


    <Link to="https://play.google.com/store/apps/details?id=com.cheeplus.ptb" target="_blank"><img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" width="168" /></Link>



      <p>
      Welcome to Pop the Balloon Game: a modern word game to challenge your brain, fingers, and most importantly, your vocabulary strength. This game is a classic balloon tap game for all ages, with carefully designed characters – The monsters from Monstar Planet!
    </p>
      <p>
In this game, every balloon has a letter on it. The player needs to help a monster called Laser Eye popping the right balloons. So, he can fill the blank in the word. There are also other fellow monsters who have different abilities. Player can call them for help during the battle time.
</p>
      <p>
No ads are shown while playing!
</p>
      <p>
All game materials are suitable for young kids.
</p>
      <p>
FEATURES
</p>
      <p>
* Total 23 stages with more than 20,000 words covered
* Challenge yourself from easy to difficult
* Earn gold to buy different cards
* Earn points to level up
</p>
      <p>
HOW TO PLAY
</p>
      <p>
- You have 60 seconds to finish the challenge.
- You'll have 10 seconds to finish each wave. The faster you pop, the higher your score. The higher the stage is, the higher your score.
- You can buy different cards. These cards can give you different advantages.
</p>
      <p>
FREE-TO-PLAY
</p>
      <p>
Please Note: Pop the Balloon is a free to play game that includes optional in-app purchases such as gems. You can turn off the payment feature by disabling in-app purchases in your device’s settings.
</p>
      <p>
Are you ready to hit the balloons? Let’s pop.
</p>
      <p>
Love it or hate it? Please review the game and let us know.
      </p>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`